"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var WatchRTCHttp = /** @class */ (function () {
    function WatchRTCHttp(options) {
        this.debug = false;
        if (WatchRTCHttp._instance) {
            console.info.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["WatchRTCSocket instance already created"]));
        }
        else {
            WatchRTCHttp._instance = this;
            this.debug = !!options.debug;
        }
    }
    WatchRTCHttp.prototype.trace = function (url, projectId, rtcRoomId, rtcPeerId) {
        var data = [];
        for (var _i = 4; _i < arguments.length; _i++) {
            data[_i - 4] = arguments[_i];
        }
        var args = Array.prototype.slice.call(data);
        args.push(Date.now());
        if (args[1] instanceof RTCPeerConnection) {
            args[1] = args[1].__rtcStatsId;
        }
        var response = fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: data,
                projectId: projectId,
                rtcRoomId: rtcRoomId,
                rtcPeerId: rtcPeerId,
            }),
        });
        response
            .then()
            .catch(function (err) { return console.log.apply(console, __spreadArrays(utils_1.logPrefix("error"), [err.message, { err: err.stack }])); });
    };
    return WatchRTCHttp;
}());
exports.default = WatchRTCHttp;
