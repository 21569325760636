"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapStream = exports.addEvent = exports.enableDataCollection = exports.disableDataCollection = exports.addKeys = exports.setUserRating = exports.addTags = exports.setConfig = exports.init = void 0;
require("./types");
var sdk_1 = require("./sdk");
/**
 * Initialize SDK. Can be called multiple times but it will be initialized only at the first time.
 * Use 'setConfig' function after if you don't have rtcRoomId and rtcPeerId yet.
 * @param watchrtc
 * @param prefixesToWrap
 */
exports.init = function (watchrtcConfig) {
    sdk_1.initSDK(watchrtcConfig, [""]);
};
/**
 * You also can use watchRTC.setConfig function to set watchRTC configuration after calling init()
 * and before the creation of RTCPeerConnection objects
 */
exports.setConfig = function (watchrtcConfig) {
    sdk_1.setConfig(watchrtcConfig);
};
/**
 * @deprecated Tags no longer supported. Use addKeys instead
 * Add tags for peer session
 * @param rtcTags[]
 */
exports.addTags = function (
/** ["tag1", "tag2", "tag3"] */
_rtcTags) { };
/**
 * Set user rating and/or comment for peer session
 * @param rating number from 1 to 5
 * @param comment string
 */
exports.setUserRating = function (
/** 1 | 2 | 3 | 4 | 5 */
rating, comment) {
    sdk_1.setUserRating(rating, comment);
};
/**
 * Add keys for peer session
 * @param keys
 */
exports.addKeys = function (
/** { "key1": "value1", "key2": "value2"} */
keys) {
    sdk_1.addKeys(keys);
};
/**
 * Disables data collection
 */
exports.disableDataCollection = function () {
    sdk_1.disableDataCollection();
};
/**
 * Enables data collection
 */
exports.enableDataCollection = function () {
    sdk_1.enableDataCollection();
};
exports.addEvent = function (event) {
    sdk_1.addEvent(event);
};
exports.mapStream = function (id, name) {
    sdk_1.mapStream(id, name);
};
exports.default = {
    /**
     * Initialize SDK. Can be called multiple times but it will be initialized only at the first time.
     * Use 'setConfig' function after if you don't have rtcRoomId and rtcPeerId yet.
     * @param watchrtc
     * @param prefixesToWrap
     */
    init: exports.init,
    /**
     * @deprecated Tags no longer supported. Use addKeys instead
     * Add tags for peer session
     * @param rtcTags[]
     */
    addTags: exports.addTags,
    /**
     * Set user rating and/or comment for peer session
     * @param rating number from 1 to 5
     * @param comment string
     */
    setUserRating: exports.setUserRating,
    /**
     * Add keys for peer session
     * @param keys
     */
    addKeys: exports.addKeys,
    /**
     * You also can use watchRTC.setConfig function to set watchRTC configuration after calling init()
     * and before the creation of RTCPeerConnection objects
     */
    setConfig: exports.setConfig,
    /**
     * Disables data collection
     */
    disableDataCollection: exports.disableDataCollection,
    /**
     * Enables data collection
     */
    enableDataCollection: exports.enableDataCollection,
    addEvent: exports.addEvent,
    mapStream: exports.mapStream,
};
